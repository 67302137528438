import NotFound from "@/views/404.vue"

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export function rounter() {
  return [...basicRouterMap, ...menuRouterMap]
};

const basicRouterMap = [
  {
    path: "/user",
    meta: { checkLogin: false },
    redirect: '/user/login',
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/layouts/UserLayout.vue"),
    children: [
      {
        path: "/user/login",
        meta: { checkLogin: false },
        name: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/LoginView.vue"),
      },
    ]
  },
  {
    path: "*",
    meta: { checkLogin: false },
    name: "404",
    component: NotFound,
  },
];

export const menuRouterMap = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ "@/layouts/BasicLayout.vue"),
    meta: { title: "首页", checkLogin: true },
    redirect: '/userManage/userManageList',
    children: [
      // 用户管理
      {
        path: '/userManage',
        name: 'userManage',
        component: RouteView,
        redirect: '/userManage/userManageList',
        meta: { title: '用户管理', checkLogin: true, icon: 'user' },
        children: [
          {
            path: '/userManage/userManageList',
            name: 'userManageList',
            component: () => import('@/views/userManage/userManageList.vue'),
            meta: { title: '用户管理列表', checkLogin: true, }
          },
          {
            path: '/userManage/userDetail',
            name: 'userDetail',
            component: () => import('@/views/userManage/userDetail.vue'),
            meta: { title: '用户详情',hide: true, checkLogin: true, }
          }
        ]
      },
      // 财务管理
      {
        path: '/finance',
        name: 'finance',
        component: RouteView,
        redirect: '/finance/financeOrder',
        meta: { title: '财务管理', checkLogin: true, icon: 'account-book' },
        children: [
          {
            path: '/finance/financeOrder',
            name: 'financeOrder',
            component: () => import('@/views/financeManage/financeOrderList.vue'),
            meta: { title: '财务流水', checkLogin: true, }
          },
          {
            path: '/finance/userFinance',
            name: 'userFinance',
            component: () => import('@/views/financeManage/userFinanceJournalList.vue'),
            meta: { title: '用户流水', checkLogin: true, }
          }, {
            path: '/finance/consumeIntegral',
            name: 'consumeIntegral',
            component: () => import('@/views/financeManage/consumeIntegralJournalList.vue'),
            meta: { title: '待转积分流水', checkLogin: true, }
          }, {
            path: '/finance/integral',
            name: 'integral',
            component: () => import('@/views/financeManage/integralJournalList.vue'),
            meta: { title: '积分流水', checkLogin: true, }
          }, {
            path: '/finance/treasurePoint',
            name: 'treasurePoint',
            component: () => import('@/views/financeManage/treasurePointJournalList.vue'),
            meta: { title: '宝点流水', checkLogin: true, }
          }, {
            path: '/finance/tancoin',
            name: 'tancoin',
            component: () => import('@/views/financeManage/tancoinJournalList.vue'),
            meta: { title: '好点流水', checkLogin: true, }
          }, {
            path: '/finance/bonus',
            name: 'bonus',
            component: () => import('@/views/financeManage/bonusJournalList.vue'),
            meta: { title: '分红列表', checkLogin: true, }
          }
        ]
      },
      //工单管理
      {
        path: '/orderManage',
        name: 'orderManage',
        component: RouteView,
        redirect: '/orderManage/withdrawOrderList',
        meta: { title: '工单管理', checkLogin: true, icon: 'audit' },
        children: [
          {
            path: '/orderManage/withdrawOrderList',
            name: 'withdrawOrderList',
            component: () => import('@/views/orderManage/withdrawOrderList.vue'),
            meta: { title: '提现列表', checkLogin: true, }
          },
          {
            path: '/orderManage/withdrawOrderDetail',
            name: 'withdrawOrderDetail',
            component: () => import('@/views/orderManage/withdrawOrderDetail.vue'),
            meta: { title: '提现详情',hide: true, checkLogin: true, }
          }
        ]
      },
      // 服务商管理
      {
        path: '/serviceProviderManage',
        name: 'serviceProviderManage',
        component: RouteView,
        redirect: '/serviceProviderManage/serviceProvider',
        meta: { title: '服务商管理', checkLogin: true, icon: 'money-collect' },
        children: [
          {
            path: '/serviceProviderManage/serviceProvider',
            name: 'serviceProvider',
            component: () => import('@/views/serviceProviderManage/serviceProviderList.vue'),
            meta: { title: '服务商列表', checkLogin: true, }
          },
        ]
      },
      // 服务站管理
      {
        path: '/serviceStationManage',
        name: 'serviceStationManage',
        component: RouteView,
        redirect: '/serviceStationManage/serviceStation',
        meta: { title: '服务站管理', checkLogin: true, icon: 'global' },
        children: [
          {
            path: '/serviceStationManage/serviceStation',
            name: 'serviceStation',
            component: () => import('@/views/serviceStationManage/serviceStationList.vue'),
            meta: { title: '服务站列表', checkLogin: true, }
          },
          {
            path: '/serviceStationManage/serviceStationJournal',
            name: 'serviceStationJournal',
            component: () => import('@/views/serviceStationManage/serviceStationJournalList.vue'),
            meta: { title: '服务站流水', checkLogin: true, }
          },
          {
            path: '/serviceStationManage/serviceStationClearing',
            name: 'serviceStationClearing',
            component: () => import('@/views/serviceStationManage/serviceStationClearingList.vue'),
            meta: { title: '服务站结算', checkLogin: true, }
          }
        ]
      },
      // 功能设置
      {
        path: '/settingManage',
        name: 'settingManage',
        component: RouteView,
        redirect: '/settingManage/androidVersion',
        meta: { title: '版本管理', checkLogin: true, icon: 'appstore' },
        children: [
          {
            path: '/settingManage/androidVersion',
            name: 'androidVersion',
            component: () => import('@/views/settingManage/androidVersionSetting.vue'),
            meta: { title: '安卓版本管理', checkLogin: true, }
          }
        ]
      },]
  }]